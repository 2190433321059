// CHECKBOX
.checkbox {
	position: relative;
	// Checkbox in the dropdown menu
	&.dropdown-item {
		padding: 0 !important;

		[type="checkbox"] + label {
			padding: 0.75rem 1.0625rem 0.75rem rem-calc(46);

			&:before {
				left: rem-calc(17);
				top: rem-calc(13);
			}

			&:after {
				left: rem-calc(21);
				top: rem-calc(17);
			}
		}
	}

	&-label {
		display: block;
		width: 100%;
	}

	&-inline {
		.checkbox-label {
			width: auto;
		}
	}

	input[type="checkbox"] {
		display: none;

		& + label {
			cursor: pointer;
			margin-bottom: 0;
			padding-left: $checkbox-label-padding;
			position: relative;
			// checkbox aspect
			&:before,
			&:after {
				position: absolute;
			}

			&:before {
				content: "";
				border: 1px solid $gray-400;
				background: $white;
				height: $checkbox-size;
				left: 0;
				top: 0;
				width: $checkbox-size;
			}

			&:after {
				@include animate(opacity transform);
				content: "\e928";
				font-family: "iconfont";
				color: $checkbox-color;
				left: rem-calc(4);
				line-height: 1;
				opacity: 0;
				transform: scale(0);
				top: rem-calc(4);
			}
			// hover style just for information
			&:hover:before {
				box-shadow: 0 0 0 2px $gray-200;
			}
		}

		&:checked + label:after {
			opacity: 1;
			transform: scale(1);
		}
		
		&:disabled + label {
			//background-color: $gray-100;
			cursor: not-allowed;
			opacity: 0.5;

			&:hover:before {
				box-shadow: none;
			}
		}
	}
}

// RADIO BUTTONS
.form-check {
	// Radio buttons in the dropdown menu
	&.dropdown-item {
		padding: 0 !important;

		[type="radio"] + label {
			padding: 0.75rem 1.0625rem 0.75rem rem-calc(46);
			&:before {
				left: rem-calc(17);
				top: rem-calc(13);
			}
			&:after {
				left: rem-calc(21);
				top: rem-calc(17);
			}
		}
	}

	&-label {
		display: block;
		width: 100%;
	}

	&-inline {
		.form-check-label {
			width: auto;
		}
	}

	[type="radio"] {
		display: none;

		& + label {
			cursor: pointer;
			margin-bottom: rem-calc(10);
			padding-left: rem-calc(30);
			position: relative;

			// radio aspect
			&:before,
			&:after {
				border-radius: 50%;
				content: "";
				position: absolute;
			}
			&:before {
				border: 1px solid $gray-400;
				background-color: $white;
				height: rem-calc(21);
				left: 0;
				top: rem-calc(2);
				width: rem-calc(21);
			}
			&:after {
				@include animate(opacity transform);
				background-color: $radio-color;
				height: rem-calc(13);
				left: rem-calc(4);
				opacity: 0;
				transform: scale(0);
				top: rem-calc(6);
				width: rem-calc(13);
			}

			// hover style just for information
			&:hover:before {
				box-shadow: 0 0 0 2px $gray-200;
			}
		}

		&:checked + label:after {
			opacity: 1;
			transform: scale(1);
		}

		&:disabled + label {
			background-color: $gray-100;
			cursor: not-allowed;
			opacity: 0.5;
			&:hover:before {
				box-shadow: none;
			}
		}
	}
}

// CUSTOM STYLES
.form-group {
	label {
		font-size: $label-font-size;
		font-weight: $label-font-weight;
		text-transform: $label-text-transform;
	}
}

.form-control {
	@include animate(border-color box-shadow color);
	&:valid,
	&:invalid {
		@include animate(border-color box-shadow color);
	}
}

.form-text {
	line-height: 1.25;
}

.custom-file-label::after {
	content: "Durchsuchen";
}
