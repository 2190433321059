@import "variables";

@font-face {
  font-family: '#{$icomoon-font-family}';
  src:  url('#{$icomoon-font-path}/#{$icomoon-font-family}.eot?noq7ok');
  src:  url('#{$icomoon-font-path}/#{$icomoon-font-family}.eot?noq7ok#iefix') format('embedded-opentype'),
    url('#{$icomoon-font-path}/#{$icomoon-font-family}.ttf?noq7ok') format('truetype'),
    url('#{$icomoon-font-path}/#{$icomoon-font-family}.woff?noq7ok') format('woff'),
    url('#{$icomoon-font-path}/#{$icomoon-font-family}.svg?noq7ok##{$icomoon-font-family}') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

[class^="icon-"], [class*=" icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: '#{$icomoon-font-family}' !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-calendar {
  &:before {
    content: $icon-calendar; 
  }
}
.icon-caret-down {
  &:before {
    content: $icon-caret-down; 
  }
}
.icon-caret-down-o {
  &:before {
    content: $icon-caret-down-o; 
  }
}
.icon-caret-left {
  &:before {
    content: $icon-caret-left; 
  }
}
.icon-caret-left-o {
  &:before {
    content: $icon-caret-left-o; 
  }
}
.icon-caret-right {
  &:before {
    content: $icon-caret-right; 
  }
}
.icon-caret-right-o {
  &:before {
    content: $icon-caret-right-o; 
  }
}
.icon-caret-up {
  &:before {
    content: $icon-caret-up; 
  }
}
.icon-caret-up-o {
  &:before {
    content: $icon-caret-up-o; 
  }
}
.icon-check {
  &:before {
    content: $icon-check; 
  }
}
.icon-chevron-up {
  &:before {
    content: $icon-chevron-up; 
  }
}
.icon-chevron-down {
  &:before {
    content: $icon-chevron-down; 
  }
}
.icon-chevron-left {
  &:before {
    content: $icon-chevron-left; 
  }
}
.icon-chevron-right {
  &:before {
    content: $icon-chevron-right; 
  }
}
.icon-close {
  &:before {
    content: $icon-close; 
  }
}
.icon-dashboard {
  &:before {
    content: $icon-dashboard; 
  }
}
.icon-desktop {
  &:before {
    content: $icon-desktop; 
  }
}
.icon-fill {
  &:before {
    content: $icon-fill; 
  }
}
.icon-hand {
  &:before {
    content: $icon-hand; 
  }
}
.icon-invation {
  &:before {
    content: $icon-invation; 
  }
}
.icon-license {
  &:before {
    content: $icon-license; 
  }
}
.icon-login {
  &:before {
    content: $icon-login; 
  }
}
.icon-logout {
  &:before {
    content: $icon-logout; 
  }
}
.icon-mail {
  &:before {
    content: $icon-mail; 
  }
}
.icon-mail-open {
  &:before {
    content: $icon-mail-open; 
  }
}
.icon-menu {
  &:before {
    content: $icon-menu; 
  }
}
.icon-money {
  &:before {
    content: $icon-money; 
  }
}
.icon-minus {
  &:before {
    content: $icon-minus; 
  }
}
.icon-order-alphabetical {
  &:before {
    content: $icon-order-alphabetical; 
  }
}
.icon-order-numeric {
  &:before {
    content: $icon-order-numeric; 
  }
}
.icon-order-thematic {
  &:before {
    content: $icon-order-thematic; 
  }
}
.icon-type-rss {
  &:before {
    content: $icon-type-rss; 
  }
}
.icon-reg {
  &:before {
    content: $icon-reg; 
  }
}
.icon-file-html {
  &:before {
    content: $icon-file-html; 
  }
}
.icon-file-rss {
  &:before {
    content: $icon-file-rss; 
  }
}
.icon-plus {
  &:before {
    content: $icon-plus; 
  }
}
.icon-rss-o {
  &:before {
    content: $icon-rss-o; 
  }
}
.icon-rss {
  &:before {
    content: $icon-rss; 
  }
}
.icon-search {
  &:before {
    content: $icon-search; 
  }
}
.icon-send {
  &:before {
    content: $icon-send; 
  }
}
.icon-settings {
  &:before {
    content: $icon-settings; 
  }
}
.icon-topics {
  &:before {
    content: $icon-topics; 
  }
}
.icon-user {
  &:before {
    content: $icon-user; 
  }
}
.icon-user-w {
  &:before {
    content: $icon-user-w; 
  }
}
.icon-trash {
  &:before {
    content: $icon-trash; 
  }
}
.icon-trash-full {
  &:before {
    content: $icon-trash-full; 
  }
}
.icon-user-m {
  &:before {
    content: $icon-user-m; 
  }
}
.icon-community {
  &:before {
    content: $icon-community; 
  }
}
.icon-beaker {
  &:before {
    content: $icon-beaker; 
  }
}
.icon-book {
  &:before {
    content: $icon-book; 
  }
}
.icon-book-opened {
  &:before {
    content: $icon-book-opened; 
  }
}
.icon-filter {
  &:before {
    content: $icon-filter; 
  }
}
.icon-lightning {
  &:before {
    content: $icon-lightning; 
  }
}
.icon-list {
  &:before {
    content: $icon-list; 
  }
}
.icon-list-o {
  &:before {
    content: $icon-list-o; 
  }
}
.icon-megaphone {
  &:before {
    content: $icon-megaphone; 
  }
}
.icon-message {
  &:before {
    content: $icon-message; 
  }
}
.icon-news {
  &:before {
    content: $icon-news; 
  }
}
.icon-pin {
  &:before {
    content: $icon-pin; 
  }
}
.icon-puzzle {
  &:before {
    content: $icon-puzzle; 
  }
}
.icon-radar {
  &:before {
    content: $icon-radar; 
  }
}
.icon-paper-plane {
  &:before {
    content: $icon-paper-plane; 
  }
}
.icon-star {
  &:before {
    content: $icon-star; 
  }
}
.icon-star-o {
  &:before {
    content: $icon-star-o; 
  }
}
.icon-theme {
  &:before {
    content: $icon-theme; 
  }
}
.icon-print {
  &:before {
    content: $icon-print; 
  }
}
.icon-module-focus {
  &:before {
    content: $icon-module-focus; 
  }
}
.icon-multiple-filter {
  &:before {
    content: $icon-multiple-filter; 
  }
}
.icon-balance-scale {
  &:before {
    content: $icon-balance-scale; 
  }
}
.icon-share {
  &:before {
    content: $icon-share; 
  }
}
.icon-share-alt {
  &:before {
    content: $icon-share-alt; 
  }
}
.icon-eye {
  &:before {
    content: $icon-eye; 
  }
}
.icon-database {
  &:before {
    content: $icon-database; 
  }
}
.icon-feather {
  &:before {
    content: $icon-feather; 
  }
}
.icon-download {
  &:before {
    content: $icon-download; 
  }
}
.icon-book-opened-2 {
  &:before {
    content: $icon-book-opened-2; 
  }
}
.icon-file-pdf {
  &:before {
    content: $icon-file-pdf; 
  }
}
.icon-arrow-big-down {
  &:before {
    content: $icon-arrow-big-down; 
  }
}
.icon-arrow-big-left {
  &:before {
    content: $icon-arrow-big-left; 
  }
}
.icon-arrow-big-right {
  &:before {
    content: $icon-arrow-big-right; 
  }
}
.icon-arrow-big-up {
  &:before {
    content: $icon-arrow-big-up; 
  }
}
.icon-arrow-down {
  &:before {
    content: $icon-arrow-down; 
  }
}
.icon-arrow-left {
  &:before {
    content: $icon-arrow-left; 
  }
}
.icon-arrow-right {
  &:before {
    content: $icon-arrow-right; 
  }
}
.icon-arrow-up {
  &:before {
    content: $icon-arrow-up; 
  }
}
.icon-index {
  &:before {
    content: $icon-index; 
  }
}
.icon-magazine-opened {
  &:before {
    content: $icon-magazine-opened; 
  }
}
.icon-image {
  &:before {
    content: $icon-image; 
  }
}
.icon-images {
  &:before {
    content: $icon-images; 
  }
}
.icon-cart {
  &:before {
    content: $icon-cart; 
  }
}
.icon-shopping-cart {
  &:before {
    content: $icon-shopping-cart; 
  }
}
.icon-grid-o {
  &:before {
    content: $icon-grid-o; 
  }
}
.icon-grid {
  &:before {
    content: $icon-grid; 
  }
}
.icon-microphone {
  &:before {
    content: $icon-microphone; 
  }
}
.icon-video {
  &:before {
    content: $icon-video; 
  }
}
.icon-phone {
  &:before {
    content: $icon-phone; 
  }
}
.icon-comments {
  &:before {
    content: $icon-comments; 
  }
}
.icon-comments-o {
  &:before {
    content: $icon-comments-o; 
  }
}
.icon-climate-neutral {
  &:before {
    content: $icon-climate-neutral; 
  }
}
.icon-warning-o {
  &:before {
    content: $icon-warning-o; 
  }
}
.icon-warning {
  &:before {
    content: $icon-warning; 
  }
}
.icon-invoice {
  &:before {
    content: $icon-invoice; 
  }
}
.icon-seminar {
  &:before {
    content: $icon-seminar; 
  }
}
.icon-seminar-alt {
  &:before {
    content: $icon-seminar-alt; 
  }
}
.icon-info-o {
  &:before {
    content: $icon-info-o; 
  }
}
.icon-info {
  &:before {
    content: $icon-info; 
  }
}
.icon-database-alt {
  &:before {
    content: $icon-database-alt; 
  }
}
.icon-magazine {
  &:before {
    content: $icon-magazine; 
  }
}
.icon-magazine-alt {
  &:before {
    content: $icon-magazine-alt; 
  }
}
.icon-minus-o {
  &:before {
    content: $icon-minus-o; 
  }
}
.icon-plus-o {
  &:before {
    content: $icon-plus-o; 
  }
}
.icon-pencil {
  &:before {
    content: $icon-pencil; 
  }
}
.icon-refresh {
  &:before {
    content: $icon-refresh; 
  }
}
.icon-locked {
  &:before {
    content: $icon-locked; 
  }
}
.icon-clock {
  &:before {
    content: $icon-clock; 
  }
}
.icon-alarm-clock {
  &:before {
    content: $icon-alarm-clock; 
  }
}
.icon-user-add {
  &:before {
    content: $icon-user-add; 
  }
}
.icon-user-remove {
  &:before {
    content: $icon-user-remove; 
  }
}
.icon-admin {
  &:before {
    content: $icon-admin; 
  }
}
.icon-admin-alt {
  &:before {
    content: $icon-admin-alt; 
  }
}
.icon-copy {
  &:before {
    content: $icon-copy; 
  }
}
.icon-calender-empty {
  &:before {
    content: $icon-calender-empty; 
  }
}
.icon-link {
  &:before {
    content: $icon-link; 
  }
}
.icon-link-add {
  &:before {
    content: $icon-link-add; 
  }
}
.icon-unlink {
  &:before {
    content: $icon-unlink; 
  }
}
.icon-remove {
  &:before {
    content: $icon-remove; 
  }
}

