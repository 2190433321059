// USER iNFO
.user-info {
	dl {
		margin-bottom: 0;
		word-break: break-all;
		width: 100%;
	}

	dd:not(.last) {
		@include media("screen", "<phone") {
			border-bottom: 1px solid $gray-300;
			padding-bottom: rem-calc(10);
		}
		@include media("screen", ">=phone") {
			margin-bottom: 0;
		}
	}
}
