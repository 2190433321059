// COLORS
.color {
	&-danger {
		color: $danger;
	}

	&-gray {
		color: $gray-700;
	}

	&-info {
		color: $info;
	}

	&-success {
		color: $success;
	}

	&-white {
		color: $white;
	}
}
