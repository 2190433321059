// GROUP
.btn-group {
    .btn {
        margin-top: rem-calc(20);
        &:not(:last-child) {
            margin-right: rem-calc(20);
        }
    }
}


// BUTTON BLOCK
.buttons-block {
	margin-bottom: rem-calc(20);
	@include media('screen', '>=desktop') {
		margin-bottom: rem-calc(55);
	}

	.btn {
		margin-bottom: rem-calc(20);
		@include media('screen', '>=tablet') {
			margin-bottom: 0;
		}
		+ .btn {
			margin-left: rem-calc(16);
		}
	}
}