// FOOTER TOP
.footer-top {
	background-color: $footer-service-bg;

	a {
		// color: $footer-link-color;
		font-size: calc(#{$footer-service-link-font-size} / 1.125);
		font-weight: $footer-service-link-font-weight;
		@include media("screen", "<desktop") {
			[class*="icon-"] {
				opacity: .75;
			}
		}
		// @include media("screen", "<widescreen") {
		// 	font-size: calc(#{$footer-service-link-font-size} / 1.125);
		// }
		@include media("screen", ">widescreen") {
			font-size: $footer-service-link-font-size;
		}
	}

	// DIVIDER
	.divider {
		border-top: $footer-divider-border;
		@include media("screen", "<desktop") {
			margin-top: 2rem;
		}
	}

	// SERVICE
	&--service {
		color: $footer-service-color;
		display: flex;
		font-size: $footer-service-font-size;
		font-weight: $footer-service-font-weight;
		margin-top: 2.25rem;
		margin-bottom: 2rem;
		text-transform: $footer-service-text-transform;
		@include media("screen", "<desktop") {
			justify-content: center;
		}
		@include media("screen", ">=desktop") {
			flex-direction: column;
			line-height: rem-calc(28);
		}
	}

	// LIST OVERRIDE
	.list-horizontal {
		@include media("screen", "<tablet") {
			flex-direction: column;
		}
		@include media("screen", ">=desktop") {
			justify-content: flex-end;
			margin-right: -1rem;
		}

		a {
			@include media("screen", "<tablet") {
				justify-content: center;
				margin-left: 50%;
				transform: translateX(-50%);
				width: 100%;
			}
		}
	}
}