// ORDER
.order {
	&-info {
		dl {
			@include media("screen", ">=tablet") {
				display: flex;
				align-items: center;
				flex-wrap: wrap;
				margin-bottom: 0;
			}

			&.dl-inline {
				display: flex;
				justify-content: flex-start;
				flex-wrap: wrap;

				dt {
					width: 40%;
				}

				dd {
					width: 60%;
					@include media("screen", "<phone") {
						justify-content: flex-end;
					}
				}

				&-featured {
					display: flex;
					flex-wrap: wrap;

					dt {
						width: 70%;
						@include media("screen", ">=phone", "<tablet") {
							width: 62%;
						}
					}

					dd {
						width: 30%;
						@include media("screen", "<phone") {
							justify-content: flex-end;
						}
						@include media("screen", ">=phone", "<tablet") {
							width: 38%;
						}
						@include media("screen", ">=tablet") {
							justify-content: flex-end;
						}
					}
				}
			}

			&:not(.dl-inline):not(.dl-inline-featured) {
				dd:not(.last) {
					@include media("screen", "<tablet") {
						border-bottom: 1px solid $gray-300;
						padding-bottom: rem-calc(10);
					}
				}
			}
		}

		dt {
			font-weight: 400;
			@include media("screen", ">=tablet") {
				margin-bottom: 0.5rem;
				width: 40%;
			}
		}

		dd {
			display: flex;
			align-items: center;
			justify-content: space-between;
			font-weight: 700;
			word-break: break-word;
			@include media("screen", ">=tablet") {
				margin-bottom: 0.5rem;
				width: 60%;
			}
		}
	}
}
