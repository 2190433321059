.table-result-item {
	@include animate(all);
	justify-content: space-between;
	&:hover,
	&:focus {
		background-color: $table-result_item-bg-hover !important;
	}
	&:nth-child(even) {
		background-color: $table-result_item-bg;
	}

	.table-result-status {
		*[class*="icon-"] {
			font-size: rem-calc(18);
		}

		.icon-locked {
			color: $table-result_item_icon-locked-color;
		}
		.icon-minus-o {
			color: $table-result_item_icon-deleted-color;
		}
		.icon-check {
			color: $table-result_item_icon-success-color;
		}
	}

	.table-result-edit {
		[class*="icon-"] {
			@include animate(opacity);
			color: $table-result_item_edit-color;
			opacity: 0.5;
			&:hover,
			&:focus {
				opacity: 1;
			}
		}
	}

	// Direktstartlinks
	&.link-result {
		@include media("screen", "<desktop") {
			align-items: center;
		}

		.table-result-product {
			@include media("screen", "<desktop") {
				font-weight: 600;
			}
		}
	}
}
