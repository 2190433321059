// COMMON BUTTON STYLES
// $btn_icon-border-width: 0.125rem !default;
// $btn_skew-right-width: rem-calc(12) !default;
// $btn_skew-left: calc(-#{$btn_skew-right-width} / 2);
// $btn-padding-right: $btn-padding-x * 3.5;

.btn {
	@include animate(all);
	text-transform: uppercase;
	&:hover {
		box-shadow: $box-shadow;
	}

	&:not(.btn-sm):not(.btn-toggle) {
		*[class^="icon-"] {
			margin-left: 0.75rem;
		}
	}
}

.btn-round {
	display: flex;
	align-items: center;
	justify-content: center;
	padding: 0;
	height: $btn-round-size;
	width: $btn-round-size;

	*[class^="icon-"] {
		margin-left: 0 !important;
	}
}

// @each $color, $value in $theme-colors {
// 	.btn-outline-#{$color}:not(.btn-sm),
// 	.btn-#{$color}:not(.btn-sm) {
// 		&[data-placement="right"] {
// 			position: relative;
// 		}

// 		&[data-placement="right"] {
// 			*[class^="icon-"] {
// 				@include animate(background-color);
// 				display: inline-flex;
// 				align-items: center;
// 				height: calc(100% + #{$btn_icon-border-width});
// 				padding-left: $btn-padding-x / 2;
// 				padding-right: $btn-padding-x;
// 				position: absolute;
// 				top: calc(-#{$btn_icon-border-width} / 2);
// 				&::after {
// 					content: "";
// 					background-color: inherit;
// 					display: inline-block;
// 					height: 100%;
// 					left: $btn_skew-left;
// 					position: absolute;
// 					transform: skewX(-15deg);
// 					width: $btn_skew-right-width;
// 				}
// 			}

// 			&:hover,
// 			&:focus {
// 				*[class^="icon-"] {
// 					background-color: darken($value, 0%);
// 				}
// 			}
// 		}

// 		&[data-placement="right"] {
// 			padding-right: $btn-padding-right;

// 			*[class^="icon-"] {
// 				@include border-right-radius($btn-border-radius);
// 				right: rem-calc(-1);
// 			}
// 		}
// 	}

// 	.btn-#{$color}:not(.btn-sm) {
// 		&[data-placement="right"] {
// 			position: relative;

// 			*[class^="icon-"] {
// 				background-color: darken($value, 8%);
// 			}
// 		}
// 	}

// 	.btn-outline-#{$color}:not(.btn-sm) {
// 		&[data-placement="right"] {
// 			*[class^="icon-"] {
// 				&::after {
// 					border-left: rem-calc(1) solid darken($value, 8%);
// 				}
// 			}
// 			&:hover,
// 			&:focus {
// 				*[class^="icon-"] {
// 					background-color: darken($value, 8%);
// 				}
// 			}
// 		}

// 		&:not([data-placement="right"]) {
// 			*[class^="icon-"] {
// 				margin-left: 0.5rem;
// 			}
// 		}
// 	}
// }

.input-group-append {
	.btn:not([data-placement="right"]) {
		*[class^="icon-"] {
			margin-left: 0;
		}
	}
}

// ADDITIONAL BUTTON STYLES
.primaerLink {
	margin: rem-calc(20) 0;
}

.btn-search,
.dropdown-default-toggle {
	background: $white;
	border: 1px solid $gray-250;
	letter-spacing: 1.33px;
	position: relative;
	text-transform: uppercase;
	&:hover,
	&:focus {
		color: $gray-900;
		outline: none;
	}

	& > * {
		display: inline-flex;
		position: relative;
		z-index: 5;
	}
}

.btn-search {
	*[class*="icon-"],
	*[class^="icon-"] {
		font-size: 1rem;
		margin-left: rem-calc(10);
	}
}

button.close {
	@include animate(all);
	outline: 0;
}
