.nav-tabs {
	// .nav-item {
	// 	@include animate(all 0.3s);
	// 	color: $gray-800;
	// 	display: flex;
	// 	flex-direction: column;
	// 	text-align: center;
	// 	@include media("screen", "<desktop") {
	// 		max-width: 96px;
	// 		width: 96px;
	// 	}
	// 	@include media("screen", ">=desktop") {
	// 		max-width: 90px;
	// 		width: 90px;
	// 	}
	// 	@include media("screen", ">=widescreen") {
	// 		max-width: 105px;
	// 		width: 105px;
	// 	}
	// 	&.active {
	// 		background-image: linear-gradient(to top, $white 0%, $gray-100 100%);
	// 	}

	// 	& + .nav-item {
	// 		margin-left: -1px;
	// 	}

	// 	i {
	// 		font-size: rem-calc(18px);
	// 	}

	.nav-link {
		@include media("screen", "<411px") {
			text-align: center;

			& > [class*="icon-"] {
				display: block;
				margin-bottom: rem-calc(3);
				margin-right: 0 !important;
				width: 100%;
			}
		}
	}
	// }
}

// .nav-item--desc {
// 	font-size: rem-calc(9px);
// 	font-weight: 600;
// 	line-height: 1.25;
// 	margin-top: 5px;
// 	text-align: center;
// 	text-transform: uppercase;
// }

// .tab-content {
// 	padding-top: rem-calc(10px);
// 	@include media("screen", ">=tablet") {
// 		padding: rem-calc(15px);
// 	}
// 	@include media("screen", ">=desktop") {
// 		padding: rem-calc(20px 0);
// 	}
// }
