// Freischaltcode
$cell-width_promo-code: 45% !default;
$cell-width_unlock-code: 55% !default;

// Berechnung der Spalte - Order + Status
$cell-width_os: rem-calc($cell-width_order + $cell-width_status) !default;
// Berechnung der Spalte mit den Auftragsdaten - Order + Client
$cell-width_orderdata: $cell-width_order + $cell-width_client !default;
// Berechnung der linken Spalte für die Auftragsliste - Status + Orderdata + Activation
$cell-width_soca: rem-calc($cell-width_status + $cell-width_orderdata + $cell-width_activation) !default;
// Berechnung der linken Spalte für die Auftragssuche - Status + Order + Client
$cell-width_soc: rem-calc($cell-width_status + $cell-width_order + $cell-width_client) !default;
// Berechnung der Spalte mit den Benutzerdaten - Status
$cell-width_userdata: rem-calc($cell-width_status) !default;
// Berechnung der Spalte mit den Codes - Edit
$cell-width_code: rem-calc($cell-width_edit) !default;
// Berechnung der linken Spalte für die Auftragssuche - Order + Client
$cell-width_user-id: $cell-width_orderdata !default;
// Berechnung der linken Spalte für die Dokumentliste - 2xType + Edit Single
$cell-width_ese: rem-calc(($cell-width_type * 2) + $cell-width_edit-single) !default;
// Berechnung der Spalte für das Scope - Clientname + Type + Edit Single
$cell-width_scope: rem-calc($cell-width_clientname + $cell-width_type + $cell-width_edit-single) !default;

// AUFTRAGSLISTE
.order-result {
	// overflow-y: hidden;
	// width: 100%;

	// COLUMNS
	&-left,
	&-right {
		display: inherit;
	}

	&-left {
		width: 60%;
		@include media("screen", ">=desktop") {
			width: 68%;
		}
	}

	&-right {
		justify-content: flex-end;
		text-align: right;
		width: 40%;
		@include media("screen", ">=desktop") {
			width: 32%;
		}
	}

	// CELLS
	&-data {
		@include media("screen", "<desktop") {
			padding: $table-result_padding;

			.table-result-client,
			.table-result-order,
			.table-result-activation {
				padding: 0;
			}
		}
	}

	&-product {
		padding: $table-result_padding;
		width: calc(100% - #{$cell-width_os});
		@include media("screen", ">=tablet") {
			width: calc(100% - #{$cell-width_soc});
		}
		@include media("screen", ">=desktop") {
			width: calc(100% - #{$cell-width_soca});
		}
	}

	&-types {
		@include media("screen", "<desktop") {
			padding: $table-result_padding;
			min-width: calc(100 - #{$cell-width_edit-single});

			& > [class*="table-result"] {
				padding: 0;
			}

			.table-result-license {
				font-weight: 700;
			}
		}
	}
}

// SUCHERGEBNIS & Co.
.table-result {
	overflow-y: hidden;
	width: 100%;

	&-header {
		background-color: $table-result_header_bg;
		justify-content: space-between;

		div {
			font-size: $table-result_header_font-size;
			font-weight: 700;
			line-height: 1.5;
			text-transform: $table-result_header_text;
		}
		// Direktstartlinks
		&.link-result {
			@include media("screen", "<desktop") {
				align-items: flex-end;
			}
		}
	}

	&-header,
	&-body {
		min-width: rem-calc(570);
		width: 100%;
	}
	// COLUMNS
	&-left,
	&-right,
	&-code {
		display: inherit;
	}

	&-left {
		width: 50%; //56%
		@include media("screen", ">=desktop") {
			width: 68%;
		}
	}

	&-right {
		justify-content: flex-end;
		text-align: right;
		width: 50%; //44%;
		@include media("screen", ">=desktop") {
			width: 32%;
		}
	}
	// CELLS
	&-activation {
		padding: $table-result_padding;
		width: rem-calc($cell-width_activation);
	}

	&-client {
		padding: $table-result_padding;
		width: rem-calc($cell-width_client);
	}

	&-edit {
		padding: $table-result_padding;
		min-width: rem-calc($cell-width_edit);

		&.single {
			min-width: rem-calc($cell-width_edit-single);
		}

		&.double {
			min-width: 8.5rem;
		}

		a {
			margin: 0 rem-calc(7);

			&.copy-clipboard {
				border: 1px solid $gray-400;
				border-radius: 50%;
				display: inline-block;
				height: rem-calc(36);
				line-height: rem-calc(36);
				text-align: center;
				width: rem-calc(36);
			}
		}

		i {
			color: $gray-600;
			font-size: 1rem;
		}
	}

	&-email {
		@include media("screen", ">=desktop") {
			padding: $table-result_padding;
			width: 49%;
		}

		@include media("screen", ">=widescreen") {
			width: 33%; //$cell-width_email;
		}
	}

	&-license {
		padding: $table-result_padding;
		min-width: rem-calc($cell-width_license);
	}

	&-last-login {
		padding: $table-result_padding;
		min-width: rem-calc($cell-width_last-login);

		@include media("screen", ">=desktop") {
			min-width: rem-calc($cell-width_last-login + 60);
		}
	}

	&-qty-login,
	&-login {
		padding: $table-result_padding;
		min-width: rem-calc($cell-width-login);
	}

	&-name {
		font-weight: 600;

		@include media("screen", "<desktop") {
			padding-top: 0;
			padding-bottom: 0;
		}

		@include media("screen", ">=widescreen") {
			padding: $table-result_padding;
			width: 51%; //$cell-width_name;
		}
	}

	&-order,
	&-purchase {
		padding: $table-result_padding;
		width: rem-calc($cell-width_order);
	}

	&-purchase,
	&-created,
	&-last-login {
		line-height: 1.5;

		time {
			color: $gray-500;
		}
	}

	&-product {
		padding: $table-result_padding;
		width: calc(100% - #{$cell-width_os});

		@include media("screen", ">=desktop") {
			width: calc(100% - #{$cell-width_soc});
		}
	}

	&-status {
		padding: $table-result_padding;
		text-align: center;
		width: rem-calc($cell-width_status);

		&-doc {
			padding: $table-result_padding;
			text-align: center;
			width: rem-calc($cell-width_status-doc);
		}
	}

	&-scope {
		padding: $table-result_padding;
		width: calc(100% - #{$cell-width_scope});
	}

	&-type,
	&-expire,
	&-created {
		padding: $table-result_padding;
		min-width: rem-calc($cell-width_type);
	}

	&-user {
		@include media("screen", ">=widescreen") {
			padding: $table-result_padding;
			width: 49%;
		}
	}

	&-clientname {
		padding: $table-result_padding;
		width: rem-calc($cell-width_clientname);
	}

	&-userdata {
		width: calc(100% - #{$cell-width_userdata});

		@include media("screen", "<desktop") {
			padding: $table-result_padding;
		}
	}

	&-username {
		@include media("screen", ">=desktop", "<widescreen") {
			padding: $table-result_padding;
		}

		@include media("screen", ">=desktop") {
			width: 51%;
		}

		@include media("screen", ">=widescreen") {
			width: 67%;
		}
	}
	// Freischaltcode
	&-code {
		padding: $table-result_padding;
		word-break: break-all;

		&s {
			display: flex;
			width: calc(100% - #{$cell-width_code});
			word-break: inherit;
		}
	}

	&-code--promo {
		padding: $table-result_padding;
		width: $cell-width_promo-code;
	}

	&-code--unlock {
		padding: $table-result_padding;
		width: $cell-width_unlock-code;
	}
	// Darstellung nach Benutzern
	&-userid {
		padding: $table-result_padding;
		width: calc(100% - #{$cell-width_user-id}px);
	}

	&-management {
		padding: $table-result_padding;
		min-width: 11rem;
	}
	// DIREKTLINKS
	.link-result {
		.table-result-code {
			width: calc(55% - #{$cell-width_code});

			@include media("screen", ">=desktop") {
				width: calc(50% - #{$cell-width_code});
			}
		}

		.table-result-left {
			@include media("screen", "<desktop") {
				flex-direction: column;
				line-height: 1.5;
				padding: $table-result_padding;
				width: 45%;
			}

			@include media("screen", ">=desktop") {
				justify-content: space-between;
				width: 50%;
			}
		}

		.table-result-right {
			@include media("screen", "<desktop") {
				//flex-direction: column;
				//line-height: 1.5;
				//padding: $table-result_padding;
				width: 55%;
			}

			@include media("screen", ">=desktop") {
				//justify-content: space-between;
				width: 50%;
			}
		}

		.table-result-product {
			width: auto;

			@include media("screen", "<desktop") {
				padding: 0;
			}
		}

		.table-result-data {
			& > [class*="table-result"] {
				@include media("screen", "<desktop") {
					padding: 0;
				}
			}
		}
	}

	.alert-danger {
		background-color: rgba($danger, .1);
	}
}

// DOKUMENTE
.doc-result {
	&-left {
		width: calc(100% - #{$cell-width_ese});
		@include media("screen", "<desktop") {
			line-height: 1.5;
			padding: $table-result_padding;
		}
		@include media("screen", ">=desktop") {
			display: flex;
			justify-content: space-between;
		}
	}

	&-product {
		@include media("screen", "<desktop") {
			font-weight: 600;
		}
		@include media("screen", ">=desktop") {
			padding: $table-result_padding;
		}
	}

	&-data {
		& > [class*="table-result"] {
			@include media("screen", "<desktop") {
				padding: 0;
			}
		}
	}
}
