@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}

.loader {
    background-color: rgba($white, .5);
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    height: 100vh;
    position: fixed;
    width: 100vw;
    z-index: 1210;

    &-spinner {
        background-color: rgba($white, .85);
        border: 1px solid $gray-100;
        border-radius: rem-calc(10);
        box-shadow: $shadow-medium;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center; 
        height: 200px;
        left: 50%;
        position: absolute;
        top: 50%;
        transform: translate(-50%, -50%);
        width: 200px;
        z-index: 1;
        &::before {
            content: '';
            border: 5px solid $gray-400;
            border-radius: 50%;
            border-top: 5px solid $brand-color;
            margin-bottom: rem-calc(20);
            width: 60px;
            height: 60px;
            animation: spin 2s linear infinite;
            z-index: 2;
        }
        &::after {
            content: '...wird geladen';
            z-index: 2;
        }
    }
}