// STYLES
.bg-turquoise-light {
	background-color: $turkis-lightest;
}

.bg-info-light {
	background-color: $info-light;

	&.border {
		border-color: rgba($info, 0.3) !important;
	}

	hr {
		border-top-color: rgba($info, 0.3);
	}
}

.heading {
	@include media("screen", "<phone") {
		text-align: center;
	}
}

// FUNCTIONS
.settings-entry_body--open {
	display: none;
}

.settings-entry {
	position: relative;

	&.open {
		.settings-entry_body {
			display: none;
		}

		.settings-entry_body--open {
			display: block;
		}
	}
}

.settings-entry-toast {
	position: absolute;
	@include media("screen", "<phone") {
		top: rem-calc(5);
		width: 100%;
	}
	@include media("screen", ">=phone") {
		top: rem-calc(14);
		transform: translateX(50%);
		width: 50%;
	}
	@include media("screen", ">=tablet", "<widescreen") {
		top: rem-calc(-10);
		transform: translateX(0);
		width: 100%;
	}
	@include media("screen", ">=widescreen") {
		top: rem-calc(-2);
	}
}

// FORM
.input-validation-error {
	border-color: $form-feedback-invalid-color;
	&:focus {
		border-color: $form-feedback-invalid-color;
	}
}

.input-validation-valid {
	border-color: $form-feedback-valid-color;
	&:focus {
		border-color: $form-feedback-valid-color;
	}
}

.field-validation-valid {
	display: none;
}

.field-validation-error {
	color: $form-feedback-invalid-color;
}

.einladung {
	color: $gray-400;
	font-size: rem-calc(14);
	text-align: center;
}
