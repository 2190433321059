.offcanvas {
	.sub {
		position: relative;
		&::after {
			content: "\e912";
			color: $gray-400;
			font-family: "iconfont";
			position: absolute;
			right: 0;
			top: 9px;
			@include media("screen", ">=375px", "<desktop") {
				top: rem-calc(14);
			}
		}
	}
}

.offcanvas,
.offcanvas-sub {
	@include media("screen", "<411px") {
		padding: 0.5rem 1rem;
	}
	@include media("screen", "<desktop") {
		@include animate(all, 0.5s, ease);
		background: $gray-900;
		color: $white;
		cursor: pointer;
		height: 100%;
		opacity: 0;
		position: fixed;
		top: 0;
		z-index: 101;
	}
	@include media("screen", ">=411px", "<desktop") {
		padding: 1.5rem 1.25rem 0.5rem 1.25rem;
	}
	@include media("screen", "<phone") {
		right: -100%;
		width: 100%;
	}
	@include media("screen", ">=phone", "<desktop") {
		right: -25rem;
		width: 25rem;
	}

	&.expanded {
		opacity: 1;
		right: 0;
	}
}

.offcanvas ul,
.offcanvas-sub ul {
	@include media("screen", "<desktop") {
		list-style: none;
		margin: 0;
		padding: 0;
		width: 100%;
	}
}

.offcanvas-sub {
	@include media("screen", "<375px") {
		padding-bottom: rem-calc(60);
	}
	@include media("screen", ">=375px", "<desktop") {
		padding-bottom: rem-calc(40);
	}
	@include media("screen", "<desktop") {
		height: auto !important;
		z-index: 102;

		.nav-link:not(.back) {
			padding-left: rem-calc(30);
		}
	}
}

// Off-Canvas Toggler
.icon-bar {
	@include animate(all, 0.2s);
	width: 22px;
	height: 2px;
	background-color: $navbar-toggler-color; //$white;
	display: block;
	margin-top: rem-calc(4);
}

.navbar-toggler {
	border: none;
	background: transparent !important;
	// Off-Canvas Animation
	.top-bar {
		transform: rotate(0);
		transform-origin: 10% 10%;
	}

	.middle-bar {
		opacity: 1;
	}

	.bottom-bar {
		transform: rotate(0);
		transform-origin: 10% 90%;
	}

	&.expanded {
		.icon-bar {
			background-color: $white;
		}

		.toggler-desc {
			opacity: 0;
		}

		.top-bar {
			transform: translate(3px) rotate(45deg);
		}

		.middle-bar {
			opacity: 0;
		}

		.bottom-bar {
			transform: translate(3px) rotate(-45deg);
		}
	}
}
