.navbar {
	width: 100%;
	font-size: rem-calc(16);
	order: 1;
	// background: $gray-900;
	@include media("screen", ">=desktop") {
		background: $gray-900;
		width: calc(100% - 32px);
		font-size: rem-calc(12);
		order: 0;
		margin: 0 rem-calc(16);
	}

	.nav-link {
		padding: rem-calc(10) 0;
		@include media("screen", ">=375px") {
			padding-top: rem-calc(12);
			padding-bottom: rem-calc(12);
		}
		@include media("screen", "<411px") {
			font-size: rem-calc(14);
		}
		@include media("screen", ">=411px") {
			padding-top: rem-calc(15);
			padding-bottom: rem-calc(15);
		}
		@include media("screen", "<desktop") {
			border-bottom: 1px solid $gray-750;
		}
		@include media("screen", ">=desktop") {
			padding: rem-calc(3.5) rem-calc(7.5) rem-calc(4.5);
		}
		&:hover {
			color: $brand-color;
		}

		&--desc {
			color: $gray-400;
			display: inline-block;
			font-size: rem-calc(13);
			font-weight: 400;
			line-height: 1.3;
			margin-left: rem-calc(30);
			width: calc(100% - 30px);
			@include media("screen", ">=375px", "<411px") {
				line-height: 1.5;
			}
			@include media("screen", ">=411px") {
				font-size: $font-size-base;
				margin-top: rem-calc(5);
			}
		}

		i {
			color: $gray-400;
			display: inline-block;
			font-size: rem-calc(16);
			margin-right: rem-calc(10);
			width: rem-calc(20);
		}

		.badge-pill {
			font-size: 0.625rem;
			font-weight: 700;
			line-height: 1.5;
			margin-left: 0.1875rem;
			text-transform: uppercase;
		}
	}

	.nav-item {
		& + .nav-item {
			@include media("screen", ">=desktop") {
				margin-left: rem-calc(18);
			}
		}

		a {
			color: $white;
		}
	}
}

.navbar-toggler {
	// background: $gray-900;
	border: none;
	border-radius: 0;
	color: $navbar-toggler-color; //$white;
	height: $header-height;
	font-size: rem-calc(12);
	font-weight: 600;
	letter-spacing: $navbar-toggler-letter-spacing;
	margin-left: auto;
	margin-right: rem-calc(-10);
	outline: 0 !important;
	position: relative;
	text-transform: uppercase;
	width: 33.3333333%;
	z-index: 110;
	@include media("screen", ">=phone") {
		width: rem-calc(115);
	}
	@include media("screen", "<desktop") {
		display: flex;
		align-items: center;
		justify-content: flex-end;
	}
}

.navbar-collapse {
	margin: 0 rem-calc(-16);
}

// BS4 Default
.toggler-icon {
	margin-left: 10%;
	margin-top: rem-calc(-6);
	width: 22px;
	@include media("screen", ">=375px") {
		margin-left: 20%;
	}
}

.toggler-desc {
	opacity: 1;
	transition-delay: 0.25s;
	@include animate(opacity, 0.25s);
}

.nav-footer {
	.nav-link {
		border-bottom: 0;
		margin: auto rem-calc(15) 0 rem-calc(15);
		padding-right: 0;
	}
}
