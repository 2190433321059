.header {
	// background: url(../images/nwb_background.svg) no-repeat 0 0;
	// background-size: 100%;
	background-color: $header-bg;
	border-bottom: $header-border-bottom;
	// height: rem-calc(100);
	position: relative;
	@include media("screen", "<phone") {
		z-index: 1000;
	}
	@include media("screen", ">=phone") {
		background-position-y: 100%;
		// height: rem-calc(80);
	}
	// @include media('screen', '>=tablet') {
	// 	height: rem-calc(150);
	// }
	// @include media('screen', '>=desktop') {
	// 	height: rem-calc(160);
	// }
	// @include media('screen', '>=widescreen') {
	// 	height: rem-calc(180);
	// }
	// @include media('screen', '>=x-widescreen') {
	// 	height: rem-calc(200);
	// }
}

.header-holder {
	position: absolute;
	top: 0;
	// background: $yellow;
	width: calc(100% - 33.3333333% - 1px); //80%;
	height: $header-height;
	padding-left: rem-calc(16);
	@include media("screen", ">=phone") {
		width: calc(100% - 125px);
	}
	@include media("screen", ">=desktop") {
		background: none;
		position: static;
		width: 100%;
		height: auto;
		padding-right: rem-calc(16);
	}
}

.logo-holder {
	@include media("screen", ">=desktop") {
		padding-top: rem-calc(24);
		padding-bottom: rem-calc(24);
	}

	img {
		height: $logo-height;
		// margin-top: $logo-margin-top;
		@include media("screen", ">=desktop") {
			height: $logo-height-lg;
		}
	}
}

.logo {
	display: block;
}
