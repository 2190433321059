@keyframes shadow-pulse
{
    0% {
        box-shadow: 0 0 0 0 rgba($shadow-pulse-color, .2);
    }
    100% {
        box-shadow: 0 0 0 35px rgba($shadow-pulse-color, 0);
    }
}

@keyframes shadow-ani
{
     0% {
          box-shadow: 0 0 0 0 rgba($yellow, .2);
     }
     100% {
          box-shadow: $box-shadow;
     }
}

@keyframes pulsar {
    0% {
        box-shadow: 0 0 0 0 rgba($shadow-pulse-color, .2);
    }

    100% {
        box-shadow: 0 0 0 24px rgba($shadow-pulse-color, 0);
    }
}