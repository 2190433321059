.footer {
	background: $footer-bg;
	bottom: 0;
	border-top: 1px solid $gray-300;
	@include media("screen", ">=tablet") {
		position: absolute;
		width: 100%;
	}

	&.magazine-footer {
		position: relative;
	}
}

.footer-container {
	@extend .container;
	@extend .d-flex;
	@extend .justify-content-between;
	@include media("screen", "<phone") {
		flex-direction: column-reverse;
		text-align: center;
	}
	@include media("screen", ">=tablet") {
		flex-direction: row;
		align-items: center;
	}

	a {
		color: $footer-link-color;
		font-weight: $footer-link-font-weight;
		&:hover {
			color: $footer-link-hover-color;
		}
	}
}