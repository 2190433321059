// PRODUCT
.product,
.product-add {
	@include media("screen", "<phone") {
		flex-direction: column;
		text-align: center;
	}

	&-img {
		& > a {
			cursor: pointer;
			display: inline-flex;
			width: rem-calc(100);
			@include media("screen", ">=phone") {
				width: rem-calc(140);
			}
		}

		picture {
			width: 100%;
		}

		img {
			height: auto;
			width: 100%;
			max-width: rem-calc(100);
			@include media("screen", ">=phone") {
				max-width: rem-calc(140);
			}
		}
	}

	&-content {
		display: flex;
		flex-direction: column;
		@include media("screen", ">=phone") {
			width: calc(100% - 10.25rem);
		}

		&--heading {
			margin-bottom: rem-calc(15);
			@include media("screen", "<phone") {
				text-align: center;
			}
		}

		&--footer {
			display: flex;
			@include media("screen", "<tablet") {
				flex-direction: column;
			}

			&-left {
				width: rem-calc(150);
				@include media("screen", "<phone") {
					margin-left: auto;
					margin-right: auto;
				}
				@include media("screen", "<tablet") {
					margin-bottom: 0.75rem;
				}
			}

			&-right {
				@include media("screen", "<phone") {
					margin-left: auto;
					margin-right: auto;
				}
				@include media("screen", "<tablet") {
					width: rem-calc(200);
				}
				@include media("screen", ">=tablet") {
					align-items: center;
					margin-top: rem-calc(22);
					width: calc(100% - 150px);
				}
			}
		}
	}

	&-badge {
		display: block;
		font-size: rem-calc(11);
		font-weight: 700;
		line-height: 1;
		&:not(:first-child) {
			margin-top: rem-calc(5);
			margin-bottom: rem-calc(8);
		}
	}

	li ul {
		display: none;
	}
}

.product,
.product-add {
	position: relative;
	@include media("screen", "<phone") {
		ul,
		label {
			text-align: left;
			width: 100%;
		}
	}

	&:not(:last-child) {
		border-bottom: 1px solid $gray-300;
		margin-bottom: rem-calc(15);
		padding-bottom: rem-calc(30);
	}
}

.product-add {
	&-img {
		& > a {
			border: $product-border;
			cursor: pointer;
			align-items: center;
			justify-content: center;
			height: rem-calc(100);
			@include media("screen", ">=phone") {
				height: rem-calc(140);
			}
			&:hover,
			&:focus {
				border-color: $primary;
			}
		}
	}
}
