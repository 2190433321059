// COMMON LINKS
a {
	@include animate(all);
	font-weight: 600;
}

.text-link {
	display: inline-flex;
	align-items: center;
	[class*="icon-"] {
		@include animate(margin-left);
		font-size: rem-calc(18);
		margin-left: 0.5rem;
	}

	&:hover,
	&:focus {
		[class*="icon-"] {
			margin-left: 1rem;
		}
	}
}

// READ MORE
.link-more {
	// display: none;
	display: inline-flex;
	align-items: center;
	text-transform: uppercase;
	color: $blue;
	font-size: rem-calc(14);
	font-weight: 600;
	letter-spacing: 0.5px;
	// @include media('screen', '>=tablet') {
	// 	display: inline-flex;
	// 	align-items: center;
	// }

	*[class^="icon-"] {
		font-size: rem-calc(8);
		margin-left: rem-calc(5);
	}
}

// DOWNLOAD
.download-link {
	line-height: 1.25;
	*[class^="icon-"] {
		color: $gray-800;
		margin-right: rem-calc(5);
	}
}

// ANCHOR LINK
.anchor-link {
	@include gradient-y($gray-200, $white);
	border: 1px solid $gray-300;
	border-radius: 50%;
	box-shadow: $box-shadow-sm;
	bottom: rem-calc(30);
	color: $gray-900;
	display: none;
	align-items: center;
	justify-content: center;
	font-size: 1rem;
	height: rem-calc(48);
	position: fixed;
	width: rem-calc(48);
	z-index: 100;
	// @include media('screen', '>=tablet') {
	// 	bottom: 36px;
	// }
	// @include media('screen', '<desktop') {
	// 	right: calc(50% - 24px);
	// }
	@include media("screen", ">=desktop") {
		display: flex;
		bottom: rem-calc(36);
		right: rem-calc(15);
	}
}

.link-edit {
	@include animate(opacity);
	display: inline-block;
	opacity: 0.5;
	padding: 0 rem-calc(10);
	&:hover,
	&:focus {
		opacity: 1;
	}

	[class*="icon-"] {
		color: $black;
		font-size: 1rem;
	}
}
