// list reset
%listreset {
	margin: 0;
	padding: 0;
	list-style: none;
}

// clearfix
%clearfix {
	&:after {
		content: "";
		display: block;
		clear: both;
	}
}

.clearfix {
	@extend %clearfix;
}

// transition
%transition {
	transition: all 0.3s ease-in-out;
}

// justify nav
%justify {
	text-align: justify;
	font-size: 1px;
	line-height: 0px;
	> * {
		display: inline-block;
		vertical-align: top;
		text-align: left;
		font-size: $font-size-base;
		line-height: $line-height-base;
	}
	&:after {
		content: "";
		width: 100%;
		display: inline-block;
		vertical-align: top;
	}
}

/// * Helper class to truncate and add ellipsis to a string too long for it to fit
/// * on a single line.
/// * 1. Prevent content from wrapping, forcing it on a single line.
/// * 2. Add ellipsis at the end of the line.
.ellipsis {
	white-space: nowrap; /* 1 */
	text-overflow: ellipsis; /* 2 */
	overflow: hidden;
}

.responsive-image {
	@include responsive-image;
}

///////////////////////////////////////
.badge-new {
	background-color: $blue;
	color: $white;
	height: 50px;
	text-transform: uppercase;
	width: 50px;
}

.tab-link {
	color: $gray-900;

	p {
		margin-top: rem-calc(10);
		margin-bottom: rem-calc(-20);
	}
}

.no-scroll {
	overflow-y: hidden;
	// padding-right: rem-calc(15px);

	@include media("screen", "<desktop") {
		height: 100vh;
		left: 0;
		right: 0;
		position: fixed;
	}
}

.no-active {
	cursor: default;
	opacity: 0.5;
}

.is-change:not(.disabled) {
	background-color: rgba($info, 0.1) !important;
	color: $info;
	// outline: 1px dotted $info;
}

.text-md-smaller {
	@include media("screen", ">=tablet", "<desktop") {
		font-size: rem-calc(12);
	}
}

// BORDERS
.border-right-white {
	@include media("screen", "<phone") {
		border-bottom: 2px solid $white;
	}
	@include media("screen", ">=phone") {
		border-right: 2px solid $white;
	}
	// @include media("screen", "<phone") {
	// 	border-bottom: 0.25rem solid $white;
	// }
	// @include media("screen", ">=phone") {
	// 	position: relative;
	// 	&::after {
	// 		content: "";
	// 		height: 100%;
	// 		border-right: 0.25rem solid $white;
	// 		margin-right: rem-calc(20);
	// 		padding-left: rem-calc(20);
	// 		position: absolute;
	// 		right: 0;
	// 		top: 0;
	// 		transform: skewX(-15deg);
	// 	}
	// }
}

// LIST
.list-horizontal {
	display: flex;
	@include media("screen", "<phone") {
		flex-direction: column;
	}
	@include media("screen", ">=phone") {
		justify-content: center;
	}

	li {
		@extend .px-3;
		@extend .py-2;
		// &:not(:last-child) {
		// 	@include media("screen", ">=phone") {
		// 		border-right: 1px solid $gray-300;
		// 	}
		// }
		// @include media("screen", ">=tablet") {
		// 	&:last-child {
		// 		padding-right: 0 !important;
		// 	}
		// }
	}

	a {
		display: flex;
		align-items: center;
		@include media("screen", "<phone") {
			// flex-direction: column;
			justify-content: center;
			margin-left: 50%;
			transform: translateX(-50%);
			width: 100%;
			// &::first-letter {
			// 	text-transform: uppercase;
			// }

			// [class*="icon-"] {
			// 	margin-bottom: rem-calc(10);
			// }
		}
	}
}

// MARKS
.marks {
	a {
		height: rem-calc(42);
		margin: 0 rem-calc(15);

		img {
			height: 100%;
			width: auto;
		}
	}
}

// WIDTH
.w-md-50 {
	@include media("screen", ">=tablet") {
		width: 50% !important;
	}
}

.w-md-75 {
	@include media("screen", ">=tablet") {
		width: 75% !important;
	}
}

.w-lg-50 {
	@include media("screen", ">=desktop") {
		width: 50% !important;
	}
}

.scale-x1 {
	transform: scale(1.25);
}
.scale-x2 {
	transform: scale(1.5);
}

.ty-sm-50 {
	@include media("screen", ">=phone") {
		display: block;
		transform: translateY(50%);
	}
}

// ANIMATION
.rotate-vertical {
	[class*="icon-"]::before {
		@include animate(all);
		display: inline-block;
	}

	&[aria-expanded="true"] {
		[class*="icon-"]::before {
			transform: rotate(-180deg);
		}
	}
}
