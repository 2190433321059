//$fonts_path: "../../../fonts/nunito" !default;
$fonts_path: "../fonts/nunito" !default;

// Nunito
@font-face {
	@include fontface("Nunito", "#{$fonts_path}/Nunito-Light", 300);
	font-display: auto;
}
@font-face {
	@include fontface("Nunito", "#{$fonts_path}/Nunito-Regular", 400);
	font-display: auto;
}
@font-face {
	@include fontface("Nunito", "#{$fonts_path}/Nunito-Italic", 400, italic);
	font-display: auto;
}
@font-face {
	@include fontface("Nunito", "#{$fonts_path}/Nunito-Bold", 600);
	font-display: auto;
}
@font-face {
	@include fontface("Nunito", "#{$fonts_path}/Nunito-BoldItalic", 600, italic);
	font-display: auto;
}
@font-face {
	@include fontface("Nunito", "#{$fonts_path}/Nunito-ExtraBold", 700);
	font-display: auto;
}
@font-face {
	@include fontface("Nunito", "#{$fonts_path}/Nunito-ExtraBoldItalic", 700, italic);
	font-display: auto;
}
