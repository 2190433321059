//$icomoon-font-path: "../../../fonts/iconfont/fonts" !default;
//@import "./Assets/fonts/iconfont/styles.scss";
$icomoon-font-path: "../fonts/iconfont/fonts" !default;
@import "./Styles/fonts/iconfont/styles.scss";

.icon-x1 {
	font-size: rem-calc(18) !important;
}
.icon-x2 {
	font-size: rem-calc(24) !important;
}
.icon-x3 {
	font-size: rem-calc(32) !important;
}
.icon-x4 {
	font-size: rem-calc(40) !important;
}
.icon-x5 {
	font-size: rem-calc(48) !important;
}

[class*="icon-circle-"] {
	border-radius: 50%;
	display: flex;
	align-items: center;
	justify-content: center;
}

.icon-circle-1 {
	height: rem-calc(32);
	width: rem-calc(32);
}

.icon-circle-2 {
	height: rem-calc(42);
	width: rem-calc(42);

	[class*="icon-"] {
		font-size: rem-calc(18);
	}
}

.icon-circle-3 {
	height: rem-calc(54);
	width: rem-calc(54);

	[class*="icon-"] {
		font-size: rem-calc(24);
	}
}

.icon-circle-4 {
	height: rem-calc(64);
	width: rem-calc(64);

	[class*="icon-"] {
		font-size: rem-calc(32);
	}
}
