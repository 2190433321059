body {
	text-align: inherit;
	overflow-y: scroll;
	@include media("screen", ">=tablet") {
		min-height: 100vh;
		padding-bottom: rem-calc(390);
		position: relative;
	}
	@include media("screen", ">=desktop") {
		padding-bottom: rem-calc(265);
	}
}

hr {
	border-width: rem-calc(1);
}

.main {
	padding-bottom: 1.5rem;
}

// CHECKBOX COUNTER
.collapse-title[data-checkbox-count]:not([data-checkbox-count="0"]) {
	&::after {
		content: "(" attr(data-checkbox-count) ")";
		display: inline-block;
		margin-left: rem-calc(5);
		color: $gray-400;
	}
}

// MULTI COLLAPSE
.multi-collapse {
	.collapse-title {
		background-color: $light;
		border: rem-calc(1) solid $gray-300;
		display: block;
		margin-top: rem-calc(5);
		margin-bottom: rem-calc(-3);
		padding: rem-calc(10) rem-calc(15);
		position: relative;
		z-index: 1;
		*[class*="icon-"] {
			margin-right: 0.5rem;
			&:before {
				@include animate(transform);
				display: inline-block;
			}
		}

		&[aria-expanded="true"] {
			*[class*="icon-"]::before {
				transform: rotate(90deg);
			}
		}
	}

	// .collapse {
	// 	margin-bottom: rem-calc(5);
	// }
}