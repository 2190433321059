﻿.toolbar-tooltip {
    background-color: $toolbar-tooltip_bg;
    border-radius: 0.375rem;
    box-shadow: 0 0.375rem 0.625rem rgba($black, 0.2);
    color: $toolbar-tooltip_color;
    font-size: 0.875rem;
    height: auto;
    /*top: 105%;*/
    /*left: -5.625rem;*/
    left: 50%;
    top: 100%;
    /*padding: 0.625rem 1.875rem 0.625rem 3.125rem;*/
    padding: 0.625rem 2.25rem 0.625rem 1rem;
    position: absolute;
    text-align: center;
    transform: translateX(-50%);
    /*width: 14.375rem;*/
    white-space: nowrap;
    z-index: 100;
    &::before {
        content: "";
        height: 0;
        width: 0;
        border-bottom: 0.375rem solid $toolbar-tooltip_bg;
        border-left: 0.375rem solid transparent;
        border-right: 0.375rem solid transparent;
        position: absolute;
        left: calc(50% - 0.3125rem);
        top: -0.375rem;
    }
}

/*.toolbar-tooltip--badge {
    background-color: $toolbar-tooltip_color;
    color: $toolbar-tooltip_bg;
    border-radius: 0.5rem;
    top: 0.875rem;
    font-size: 0.5625rem;
    font-weight: 700;
    padding: 0.0625rem 0.375rem;
    position: absolute;
    left: 0.5rem;
    text-transform: uppercase;
}*/

.toolbar-tooltip--close,
.tooltip-pointer--close {
    color: inherit;
    font-size: 0.875rem;
    line-height: 2.5rem;
    position: absolute;
    height: 2.5rem;
    width: 2.5rem;
    top: rem-calc(2);
    right: 0;

    &:focus,
    &:hover {
        opacity: 0.75;
    }
}

.tooltip-pointer--close {
    text-align: center;
    z-index: 201;
}

.tooltip-pointer-wrapper {
    display: flex;
    align-items: center;
    //justify-content: space-between;
}

/*.tooltip-point {
    animation: pulsar 2s 4 1s;
    background-color: $tooltip-point_bg;
    border-radius: .5rem;
    display: none;
    margin-right: .5rem;
    height: .75rem;
    width: .75rem;
}*/

.tooltip-pointer {
    background-color: $tooltip-point_bg;
    //border-radius: .25rem 0 0 .25rem;
    border-radius: .25rem;
    color: $white;
    display: flex;
    align-items: center;
    justify-content: center;
    height: rem-calc(16);
    left: -1.5rem;
    margin-right: .25rem;
    position: absolute;
    transition: all .3s ease-in-out;
    //transition-delay: .15s;
    width: 1.75rem;
    //z-index: 100;

    &::before,
    &::after {
        opacity: 1;
        //visibility: visible;
        //z-index: 100;
    }

    &::before {
        content: "neu";
        font-size: rem-calc(10);
        font-weight: 600;
        left: .25rem;
        line-height: 1;
        position: absolute;
        text-transform: uppercase;
        transition: opacity .3s ease-in-out;
    }

    &::after {
        content: "";
        height: 0;
        width: 0;
        border-top: rem-calc(8) solid transparent;
        border-bottom: rem-calc(8) solid transparent;
        border-left: rem-calc(7) solid $toolbar-tooltip_bg;
        border-right: 0 solid transparent;
        border-radius: .25rem;
        position: absolute;
        //left: calc(50% - 0.3125rem);
        //top: -0.375rem;
        right: rem-calc(-5);
        transition: all .15s ease-in-out;
    }

    &:hover,
    &:focus {
        cursor: pointer;
        box-shadow: 0 0 .25rem rgba($black, .3);
    }

    &.is-active {
        border-radius: rem-calc(6);
        height: auto;
        min-height: 1rem;
        //max-height: 10rem;
        padding: .75rem;
        width: auto;
        min-width: 1.75rem;
        max-width: rem-calc(280);
        z-index: 200;

        &::before,
        &::after {
            opacity: 0;
            visibility: hidden;
        }

        &::after {
            right: 0;
        }

        .tooltip-pointer-content {
            //font-size: rem-calc(14);
            height: 100%;
            opacity: 1;
            visibility: visible;
            width: 100%;
        }
    }
}

.tooltip-pointer-content {
    background-color: $toolbar-tooltip_bg;
    font-size: rem-calc(14);
    height: 0;
    line-height: 1.25;
    //margin-left: -1rem;
    opacity: 0;
    overflow: hidden;
    transition: opacity .3s ease-in-out;
    transition-delay: .3s;
    visibility: hidden;
    width: 0;

    b {
        display: block;
        margin-bottom: .5rem;
    }
}