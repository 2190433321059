// ADDRESS
.address {
	@include media("screen", "<411px") {
		.btn {
			float: right;
		}
	}

	@include media("screen", ">=411px") {
		display: flex;
		align-items: flex-start;
		//justify-content: space-between;
	}

	&-invoice {
		display: flex;
		flex-direction: column;
		width: 100%;
	}

	&-add {
		//border: .125rem dotted $info;
		//border-radius: $border-radius;
		color: $address-add_color;
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: center;
		min-height: rem-calc(178);
		height: 100%;
	}

	&-controls {
		display: flex;
		width: rem-calc(84);
	}

	&-standard {
		display: flex;
		font-size: rem-calc(10);
		line-height: 1.2;
		margin-left: auto;
		text-transform: uppercase;
		width: rem-calc(84);
	}
}
