.user-menu {
	.btn-toggle {
		border: $btn-toggle-border;
		border-radius: $btn-toggle-border-radius;
		color: $btn-toggle-color;
		font-size: rem-calc(12);
		font-weight: 600;
		line-height: 1.5;
		padding: rem-calc(10) rem-calc(15);
		// @include media("screen", ">=375px") {
		// 	letter-spacing: 0;
		// }

		i {
			display: block;
			margin: 0;
			@include media("screen", ">=desktop") {
				display: inline-block;
				vertical-align: middle;
			}
			&:first-child {
				font-size: 1rem;
			}
			&:last-child {
				font-size: rem-calc(8);
			}
		}

		& > span {
			margin: 0 rem-calc(7);
		}
	}

	&.show {
		.btn-toggle {
			background-color: $btn-toggle-active-bg;
			color: $btn-toggle-active-color;
		}
	}

	.dropdown-menu {
		// background-color: $dropdown-menu-user-bg;
		// border: $dropdown-menu-user-border;
		box-shadow: $box-shadow;
		margin-left: rem-calc(1);
		padding: 0;
		top: rem-calc(10) !important;
		width: rem-calc(400);
		&::before {
			@include triangle(22px, $gray-300, up);
			content: "";
			position: absolute;
			left: auto;
			right: rem-calc(39);
			top: rem-calc(-12);
		}
		&::after {
			@include triangle(20px, $white, up);
			content: "";
			position: absolute;
			left: auto;
			right: rem-calc(40);
			top: rem-calc(-10);
		}
	}

	.dropdown-item {
		// @include animate(all, 0.3s);
		padding: rem-calc(20);
		position: relative;
		white-space: normal;
		&:not(:last-child) {
			border-bottom: rem-calc(1) solid $gray-200;
		}

		[class*="icon-"] {
			// @include animate(background-color, 0.25s);
			// background-color: $gray-200;
			// border-radius: 50%;
			// color: $gray-700;
			display: inline-flex;
			align-items: center;
			justify-content: center;
			font-size: rem-calc(24);
			height: 100%;
			width: 100%;
		}

		&:hover,
		&:focus {
			.dropdown-item--icon {
				background-color: $white;
			}

			.dropdown-item--subtitle {
				color: $gray-900;
			}

			.drop-list-holder {
				visibility: visible;
				height: 100%;
			}

			.drop-list {
				transform: translate(0);
				opacity: 1;
			}
		}

		&--icon {
			@include animate(background-color, 0.25s);
			background-color: $dropdown-item-icon-bg;
			border: $dropdown-item-icon-border;
			border-radius: 50%;
			color: $dropdown-item-icon-color;
			height: rem-calc(48);
			top: rem-calc(18);
			width: rem-calc(48);
		}

		&--title,
		&--subtitle {
			padding-left: rem-calc(66);
		}
	}

	// .drop-list-holder {
	// 	overflow: hidden;
	// 	height: 0;
	// 	visibility: hidden;

	// 	a {
	// 		color: $gray-900;
	// 		&:hover {
	// 			color: $blue;
	// 		}
	// 	}
	// }

	// .drop-list {
	// 	@include animate(opacity height transform);
	// 	@extend %listreset;
	// 	left: 0;
	// 	top: 100%;
	// 	width: 100%;
	// 	opacity: 0;
	// 	padding-left: 20px;
	// 	z-index: 1;
	// 	transform: translateY(-100%);
	// }
}
