.statusbar {
    counter-reset: section;
    position: relative;

    &::before {
        content: '';
        background-color: $statusbar-bg;
        height: rem-calc(2);
        // margin: rem-calc(21) 17% 0 17%;
        margin-top: rem-calc(21);
        position: absolute;
        width: 100%; //66%;
    }

    &::after {
        content: '';
        background-color: $statusbar-bg-active;
        height: rem-calc(2);
        margin-top: rem-calc(21);
        position: absolute;
        width: 0;
        z-index: 1;
    }

    &[data-statusbar="25"] {
        &::after {
            width: 25%;
        }
    }

    &[data-statusbar="50"] {
        &::after {
            width: 50%;
        }
    }

    &[data-statusbar="75"] {
        &::after {
            width: 75%;
        }
    }

    &[data-statusbar="100"] {
        &::after {
            width: 100%;
        }
    }

    &-item {
        color: $statusbar-color;
        font-weight: 600;
        // display: flex;
        // flex-direction: column;
        // text-align: center;
        position: relative;
        z-index: 2;
        @include media("screen", ">=tablet") {
            &:not(:first-child):not(:last-child) {
                transform: translateX(-21px);
            }
        }

        &::before {
            counter-increment: section;
            content: counter(section);
            background-color: $statusbar-bg;
            border-radius: rem-calc(42);
            color: $white;
            display: flex;
            align-items: center;
            justify-content: center;
            font-weight: 600;
            height: rem-calc(42);
            // margin: 0 auto;
            margin-bottom: rem-calc(10);
            width: rem-calc(42);
        }

        &:not(:first-child):not(:last-child) {
            text-align: center;

            &::before {
                margin-left: auto;
                margin-right: auto;
            }
        }

        &:last-child {
            &::before {
                margin-left: auto;
            }
        }

        &.is-active {
            color: $statusbar-color-active;

            &::before {
                background-color: $statusbar-bg-active;
            }
        }
    }
}
