//
// @functions
//

// STRIP UNIT
// It strips the unit of measure and returns it
@function strip-unit($num) {
	@return $num / ($num * 0 + 1);
}

// CONVERT TO REM
@function convert-to-rem($value, $base-value: $rem-base) {
	$value: strip-unit($value) / strip-unit($base-value) * 1rem;
	@if ($value == 0rem) {
		$value: 0;
	} // Turn 0rem into 0
	@return $value;
}

/*
 Theme Name:   FoundationPress child
 Theme URI:    http://my-childtheme.dev
 Description:  FoundationPress child theme
 Author:       Ole Fredrik Lie
 Author URI:   http://olefredrik.com
 Template:     FoundationPress
 Version:      1.0.0
 License:      GNU General Public License v2 or later
 License URI:  http://www.gnu.org/licenses/gpl-2.0.html
 Text Domain:  FoundationPress child-theme
*/
@function rem-calc($size) {
	$remSize: $size / 16;
	@return #{$remSize}rem;
}

// REM CALC

// New Syntax, allows to optionally calculate on a different base value to counter compounding effect of rem's.
// Call with 1, 2, 3 or 4 parameters, 'px' is not required but supported:
//
//   rem-calc(10 20 30px 40);
//
// Space delimited, if you want to delimit using comma's, wrap it in another pair of brackets
//
//   rem-calc((10, 20, 30, 40px));
//
// Optionally call with a different base (eg: 8px) to calculate rem.
//
//   rem-calc(16px 32px 48px, 8px);
//
// If you require to comma separate your list
//
//   rem-calc((16px, 32px, 48), 8px);

// @function rem-calc($values, $base-value: $rem-base) {
// 	$max: length($values);

// 	@if $max == 1 { @return convert-to-rem(nth($values, 1), $base-value); }

// 	$remValues: ();
// 	@for $i from 1 through $max {
// 		$remValues: append($remValues, convert-to-rem(nth($values, $i), $base-value));
// 	}
// 	@return $remValues;
// }

// @function em-calc($values, $base-value: $rem-base) {
// 	$remValues: rem-calc($values, $base-value: $rem-base);

// 	$max: length($remValues);

// 	@if $max == 1 { @return strip-unit(nth($remValues, 1)) * 1em; }

// 	$emValues: ();
// 	@for $i from 1 through $max {
// 		$emValues: append($emValues, strip-unit(nth($remValues, $i)) * 1em);
// 	}
// 	@return $emValues;
// }

// colors black & white
// @example scss - Usage
//   .my-class {
//     background: black(0.15);
//     color: white(0.9);
//   }
// @function black($opacity){
// 	@return rgba(black, $opacity);
// }

// @function white($opacity){
// 	@return rgba(white, $opacity);
// }

// @function sum($value1, $value2) {
// 	@return $value1 + $value2;
// }
