// COPYRIGHT
.copyright {
	color: $footer-copyright-color;
	font-weight: $footer-copyright-font-weight;
	@include media("screen", "<tablet") {
		padding-top: 1rem;
		text-align: center;
		margin-bottom: 2rem;
	}
	@include media("screen", ">=tablet") {
		padding-top: 2rem;
		text-align: center;
		margin-bottom: 2rem;
	}

	span:not(:first-child) {
		@include media("screen", "<phone") {
			display: block;
		}
		@include media("screen", ">=phone") {
			display: inline;
			&:before {
				content: " ∙ ";
			}
		}
	}
}