.alert-default {
	background-color: $alert-default-bg;
	border: $alert-border-width solid $alert-default-border-color;
}

.alert-featured {
	margin: 3rem auto;
	padding: 1.5rem 1.25rem;

	.alert-content {
		@include media("screen", ">=phone") {
			width: calc(100% - 100px);
		}
	}
}

.alert-body {
	display: flex;
	@include media("screen", "<phone") {
		flex-direction: column;
	}
}

.alert-icon {
	margin-right: rem-calc(15);
	// width: rem-calc(50);
}

.alert-icon-rounded {
	@include media("screen", "<phone") {
		margin: 0 auto 1.25rem auto;
	}

	@include media("screen", ">=phone") {
		width: rem-calc(100);
	}

	[class*="icon-"] {
		background-color: $alert-default-icon-bg;
		border: $alert-default-icon-border;
		border-radius: 50%;
		color: $alert-default-icon-color;
		display: inline-flex;
		align-items: center;
		justify-content: center;
	}

	&.alert-icon-bg-danger {
		[class*="icon-"] {
			background-color: rgba($danger, .1);
			border-color: rgba($danger, .25);
		}
	}

	[class*="icon-x3"] {
		height: rem-calc(80);
		width: rem-calc(80);
	}
}