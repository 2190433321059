// COMMON STYLES
// .nautilog-login-target,
// .nautilog-description,
// .nautilog-login,
// .nautilog-info
.section-light {
	overflow: hidden;
	position: relative;
	padding: 60px 0;
	&::before,
	&::after {
		content: "";
		left: 0;
		position: absolute;
	}
	&::before {
		margin-top: -60px;
	}
	&::after {
		margin-bottom: -60px;
	}
}

.section-content {
	@include media("screen", "<tablet") {
		padding: 30px;
	}
	@include media("screen", ">=tablet") {
		padding: 30px 50px;
	}
	@include media("screen", ">=desktop") {
		padding: 40px 100px;
	}
}

// .nautilog-headline {
//     font-weight: 400;
//     line-height: 1.4;
//     margin-bottom: 60px;
//     &:not(h1) {
//         font-size: rem-calc(20px);
//         max-width: 730px;
//         @include media('screen', '<phone') {
//             font-size: rem-calc(16px);
//         }
//     }
// }

// .nautilog-note {
//     @include size(20px);
//     background-color: $brand-color;
//     border-radius: 100%;
//     color: $black;
//     cursor: pointer;
//     display: inline-flex;
//     font-family: Georgia, 'Times New Roman', Times, serif;
//     font-size: 16px;
//     font-style: italic;
//     font-weight: 700;
//     align-items: center;
//     justify-content: center;
//     transition: all .3s ease-in-out;
//     &::before {
//         content: 'i';
//     }
//     &:hover,
//     &:focus {
//         background-color: $black;
//         color: $white;
//     }
// }

// .nautilog-btn-group {
//     display: flex;
//     @include media('screen', '>=phone') {
//         align-items: center;
//     }
// }

// NAUTILOG DESCRIPTION
// .nautilog-description {
// 	&:not(.coming-soon) {
// 		margin-top: 20px;
// 		padding-bottom: 142px;
// 		@include media("screen", ">=phone") {
// 			padding-bottom: 202px;
// 		}
// 		@include media("screen", ">=tablet") {
// 			margin-top: 40px;
// 		}
// 		@include media("screen", ">=desktop") {
// 			padding-bottom: 178px;
// 		}
// 		@include media("screen", ">=widescreen") {
// 			padding-bottom: 170px;
// 		}
// 		&::before {
// 			@include triangle(1110px 60px, $turkis-lightest, down-left);
// 		}
// 		&::after {
// 			@include triangle(1110px 60px, $turkis-lightest, up-left);
// 		}

// 		.nautilog-content {
// 			background-color: $turkis-lightest;
// 		}

// 		.nautilog-ribbon {
// 			max-width: 245px;
// 			@include media("screen", ">=phone") {
// 				max-width: 425px;
// 			}
// 			@include media("screen", ">=desktop") {
// 				right: 80px;
// 			}
// 		}
// 	}

// 	.nautilog-headline {
// 		@include media("screen", "<phone") {
// 			font-size: rem-calc(24px);
// 		}
// 	}

// 	.nautilog-ribbon {
// 		bottom: 0;
// 		position: absolute;
// 	}

// 	p {
// 		margin-bottom: 40px;
// 		&:last-child {
// 			margin-bottom: 0;
// 		}
// 	}
// }

// SECTION DIVIDER
.section-divider {
	position: relative;
}

@each $color, $value in $theme-colors {
	.section-divider.bg-#{$color} {
		// background-color: darken($value, 8%);
		background-color: $value;
		border-left: 0.125rem solid $white;
		transform: skewX(-10deg) translateX(40%);
		// &::before {
		// 	background-color: darken($value, 8%);
		// 	transform: skewX(-10deg);
		// }
		&::after {
			background-color: inherit;
			content: "";
			height: 100%;
			position: absolute;
			width: 200%;
		}

		& + div {
			background-color: $value;
		}
	}
}
// SECTION DIVIDER : END
