// DROPDOWN
.dropdown-menu {
	box-shadow: 0 2px 1px 0 rgba($black, 0.1);
	padding-left: rem-calc(10);
	padding-right: rem-calc(10);
	max-width: 34rem;
	&[x-placement="bottom-start"] {
		&::before,
		&::after {
			content: "";
			position: absolute;
		}
		&::before {
			@include triangle(22px, $dropdown-arrow-border-color, up);
			left: rem-calc(19);
			top: rem-calc(-12);
		}
		&::after {
			@include triangle(20px, $white, up);
			left: rem-calc(20);
			top: rem-calc(-10);
		}
	}
	&[x-placement="top-start"] {
		&::before,
		&::after {
			content: "";
			position: absolute;
		}
		&::before {
			@include triangle(22px, $dropdown-arrow-border-color, down);
			left: rem-calc(19);
			bottom: rem-calc(-12);
		}
		&::after {
			@include triangle(20px, $white, down);
			left: rem-calc(20);
			bottom: rem-calc(-10);
		}
	}

	[type="submit"] {
		display: block;
		background-image: linear-gradient(180deg, $gray-400 0%, $gray-550 100%);
		color: $white;
		width: 100%;
		padding: rem-calc(16) rem-calc(14);
		border: none;
		&:hover {
			cursor: pointer;
		}
	}
}

.dropdown-item {
	@include animate(background color);
	cursor: pointer;
	position: relative;
	vertical-align: middle;
	margin-bottom: 0;
	white-space: normal;
}

.dropdown-item--icon {
	// background-color: $dropdown-item-icon-bg;
	// border: $dropdown-item-icon-border;
	// color: $dropdown-item-icon-color;
	font-size: 1rem;
	height: 16px;
	position: absolute;
	top: rem-calc(11);
	width: 16px;

	& > img {
		display: inline-block;
		margin-top: rem-calc(-7);
	}
}

.dropdown-item--title,
.dropdown-item--subtitle {
	display: block;
	padding-left: rem-calc(26);
}

.dropdown-item--title {
	color: $dropdown-item-title-color;
	// font-size: 1rem;
	font-size: rem-calc(14);
	font-weight: 600;
	text-transform: uppercase;
}

.dropdown-item--subtitle {
	color: $gray-650;
	line-height: 1.5;
	@include media("screen", "<desktop") {
		margin-top: rem-calc(5);
	}
}

// DROPDOWN DEFAULT TOGGLE
// .dropdown-toggle,
.dropdown-default-toggle {
	&::after {
		content: "";
		display: inline-block;
	}

	& + .dropdown-menu {
		background-image: linear-gradient(180deg, $white 0%, $gray-100 100%);
		padding: 0;
		top: 2px !important;

		.dropdown-item {
			border-bottom: 1px solid $gray-200;
			padding: rem-calc(12) rem-calc(17);
			&:hover {
				background-color: $gray-200;
				color: $blue;
			}
		}
	}
}

// DROPDOWN
.dropdown-btn {
	border: $dropdown-btn-border;
	display: flex;
	align-items: center;
	justify-content: space-between;
	padding: $dropdown-btn-padding-y $dropdown-btn-padding-x;

	.btn-label {
		overflow: hidden;
	}
}
