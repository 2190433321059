.license {
	@include animate(all);
	display: flex;
	align-items: center;
	justify-content: space-between;
	padding: $table-cell-padding;
	&:hover,
	&:focus {
		background-color: darken($gray-100, 4%) !important;
	}
	&:nth-child(odd) {
		background-color: $gray-100;
	}

	&-desc {
		width: calc(100% - 6.875rem);
		@include media("screen", ">=desktop") {
			width: calc(100% - #{$cell-width_desc});
		}
	}

	&-free {
		width: rem-calc($cell-width_free);
	}

	&-header {
		background-color: $gray-200;
		align-items: flex-start;
		font-size: rem-calc(11);
		font-weight: 700;
		line-height: 1.5;
		padding-left: rem-calc(42);
		text-transform: uppercase;
	}

	&-left {
		width: rem-calc(110);
		@include media("screen", ">=desktop") {
			display: flex;
			width: $cell-width_desc;
		}
	}

	&-order {
		width: rem-calc($cell-width_order);
		@include media("screen", "<desktop") {
			font-weight: 700;
		}
	}
}

.modal {
	.license {
		@include media("screen", "<phone") {
			flex-direction: column;

			.btn {
				margin-top: .625rem;
			}
		}
	}
}

.modal-show-licenses_list_item {
	@include media("screen", ">=phone") {
		margin-right: auto;
	}
	@include media("screen", ">=phone") {
		max-width: calc(100% - 230px);
	}
}

.transfer-license-admin {
	display: inline-block;
	margin-bottom: 1rem;
}