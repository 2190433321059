// TOGGLER
.toggler {
	display: inline-block;
	height: rem-calc($toggler-size);
	margin-left: rem-calc($toggler-size * 2);
	position: relative;
	width: rem-calc($toggler-size * 2);

	&::before {
		@include animate(all);
		content: attr(data-value);
		font-size: rem-calc(11);
		left: -100%;
		padding-right: rem-calc(5);
		position: absolute;
		text-align: right;
		text-transform: uppercase;
		width: calc(100% - 0.3125rem);
	}

	&-slider {
		position: absolute;
		cursor: pointer;
		top: 0;
		left: 0;
		right: 0;
		bottom: 0;
		background-color: $toggler-bg;
		transition: 0.4s;

		&:before {
			@include animate(all);
			border-radius: 50%;
			position: absolute;
			content: "";
			height: rem-calc($toggler-size - 4); //$toggler_slider-size;
			width: rem-calc($toggler-size - 4); //$toggler_slider-size;
			left: rem-calc(2);
			bottom: rem-calc(2);
			background-color: $toggler_slider-color;
		}
	}

	input {
		display: flex;
		align-items: center;
		height: 0;
		margin-bottom: 0;
		opacity: 0;
		width: 0;

		&:checked + .toggler-slider {
			background-color: $toggler-bg-active;

			&:before {
				transform: translateX(rem-calc($toggler-size));
			}
		}

		&:focus + .toggler-slider {
			box-shadow: 0 0 rem-calc(1) $toggler-bg-active;
		}

		&:disabled {
			& + .toggler-slider {
				//background-color: $gray-300;
				cursor: not-allowed;
				opacity: 0.4;
			}
		}
	}
}
